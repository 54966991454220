import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import { Chart as ChartJS } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';
import moment from "moment";

// Register the zoom plugin
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

interface Props {
  data: IUserDeviceData[];
  height: number;
  statik: "0" | "1"
}


interface ChartRefType {
  resetZoom: () => void;
}

const AngleChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef
  const chartRef2 = useRef<ChartRefType | null>(null); // Specify the type of chartRef


  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const resetZoom2 = () => {
    if (chartRef2 && chartRef2.current) {
      chartRef2.current.resetZoom();
    }
  };

  let datasests = [];
  let datasests2 = [];

  if (chartData[0].temp) {
    datasests.push(
      {
        label: 'Sıcaklık (°C)',
        data: chartData.map((item) => item.temp),
        borderColor: CHART_COLORS.red,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y1',
        hidden: true,
      }
    )
  }

  if (chartData[0].temp) {
    datasests2.push(
      {
        label: 'Sıcaklık (°C)',
        data: chartData.map((item) => item.temp),
        borderColor: CHART_COLORS.red,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y1',
        hidden: true,
      }
    )
  }
  

  if (chartData[0].deflection) {
    datasests2.push(
      {
        label: 'Sapma (mm)',
        data: chartData.map((item) => item.deflection),
        borderColor: CHART_COLORS.purple,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }

  if (chartData[0].angle_x) {
    datasests.push(
      {
        label: 'Roll (°)',
        data: chartData.map((item) => item.angle_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].angle_y) {
    datasests.push(
      {
        label: 'Pitch (°)',
        data: chartData.map((item) => item.angle_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].angle_z) {
    datasests.push(
      {
        label: 'Yaw (°)',
        data: chartData.map((item) => item.angle_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].roll_mm) {
    datasests2.push(
      {
        label: 'Roll(mm)',
        data: chartData.map((item) => item.roll_mm),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].pitch_mm) {
    datasests2.push(
      {
        label: 'Pitch (mm)',
        data: chartData.map((item) => item.pitch_mm),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  return (
    <>
      {(chartData.length > 0 && (chartData[0].temp || chartData[0].deflection || chartData[0].roll_mm || chartData[0].pitch_mm )) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '90%', height: '50vh' }}>
          <button onClick={resetZoom2} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef2.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'Sapma Grafiği'
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    title: {display: true,text: 'Zaman'},
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                    title: {display: true,text: 'Eğim (mm)'},
                  },
                  y1: {
                    type: "linear",
                    display: chartData[0].temp ? true : false,
                    position: 'right',
                    title: {display: true,text: 'Sıcaklık (°C)'},
                    grid: {
                      drawOnChartArea: false
                    },
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests2
            }}
          />
        </div>
      )}
      {(chartData.length > 0 && (chartData[0].temp || chartData[0].angle_x || chartData[0].angle_y || chartData[0].angle_z)) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '90%', height: '50vh' }}>
          <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'Eğim Grafiği'
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    title: {display: true,text: 'Zaman'},
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                    title: {display: true,text: 'Eğim (°)'},
                  },
                  y1: {
                    type: "linear",
                    display: chartData[0].temp ? true : false,
                    position: 'right',
                    title: {display: true,text: 'Sıcaklık (°C)'},
                    grid: {
                      drawOnChartArea: false
                    },
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests
            }}
          />
        </div>
      )}
    </>
  )
}
export default AngleChartJS;
