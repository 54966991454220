import { Button, Input, Select, Option, Progress, Typography } from "@material-tailwind/react";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { API_URL } from "../../../common/AppSettings";
import { IDevice, IDeviceError } from "../../../models/AdminPanel";
import { IPhotoUploadResponse } from "../../../models/Response";
import DeleteModal from "../../../components/DeleteModal";
interface Props {
    onUpdated: () => void
    onCancel: () => void
    selectedDevice: IDevice
}
const DeviceUpdateModal = (props: Props) => {
    const [selectedDevice, setSelectedDevice] = useState<IDevice>(props.selectedDevice);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const fileUploadRef = useRef<any>();
    const [progressVal, setProgressVal] = useState(100);
    const [file, setFile] = useState();
    const [error, setError] = useState<IDeviceError>({ veri_gonderme_suresi: false, o_sayisi: false, gyro_esik: false, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false });
    const DeviceUpdate = async () => {
        if (selectedDevice?.device_seri_no.trim() === "" ||
            selectedDevice?.device_name.trim() === "" ||
            selectedDevice?.device_password.trim() === "" ||
            (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined) ||
            (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined) ||
            (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined) ||
            (selectedDevice.gyro_esik < 0.001 || Number.isNaN(selectedDevice.gyro_esik)) ||
            (selectedDevice.o_sayisi < 1 || Number.isNaN(selectedDevice.o_sayisi)) ||
            (selectedDevice.veri_gonderme_suresi < 0 || Number.isNaN(selectedDevice.veri_gonderme_suresi))
        ) {
            setError({
                veri_gonderme_suresi: selectedDevice.veri_gonderme_suresi < 0 || Number.isNaN(selectedDevice.veri_gonderme_suresi),
                o_sayisi: selectedDevice.o_sayisi < 1 || Number.isNaN(selectedDevice.o_sayisi),
                gyro_esik: selectedDevice.gyro_esik < 0.001 || Number.isNaN(selectedDevice.gyro_esik),
                device_seri_no: selectedDevice?.device_seri_no.trim() === "",
                device_name: selectedDevice?.device_name.trim() === "",
                device_password: selectedDevice?.device_password.trim() === "",
                coefficient_x: (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined),
                coefficient_y: (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined),
                coefficient_z: (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined),
            })
        }
        else {
            setError({ ...error, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false })
            if (selectedDevice) {
                if (file) {
                    UpdateOrCreateDeviceFile();
                }
                else {
                    UpdateDevice();
                }
            }
        }
    }
    const UpdateDevice = (file_url?: string) => {
        if (file_url) {
            selectedDevice.device_pdf_url = file_url;
        }
        API.getInstance().UpdateDevice(selectedDevice)
            .then((response) => {
                toast.success(response.data.message);
                props.onUpdated();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }
    const UpdateOrCreateDeviceFile = async () => {
        setButtonDisabled(true);
        const formData = new FormData();
        formData.append("pdf", file!);
        const URL = API_URL + "/CreateDeviceFileUpload.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            if (selectedDevice) {
                toast.success(response.data.message)
                setProgressVal(100);
                UpdateDevice(response.data.url);
                // setSelectedDevice({ ...selectedDevice, device_pdf_url: response.data.url })
            }
            else {
                toast.error("Cihaz seçilmemiştir.");
            }
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Dosya yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        }).finally(() => {
            setButtonDisabled(false);
        })
    }



    const [deleteModal, setDeleteModal] = useState(false);
    const [deletePhotoIndex, setDeletePhotoIndex] = useState(0);

    const AddPhoto = (photoUrl: string) => {
        if (selectedDevice.device_photos) {
            setSelectedDevice({ ...selectedDevice, device_photos: [...selectedDevice.device_photos, photoUrl] })
        }
        else {
            setSelectedDevice({ ...selectedDevice, device_photos: [photoUrl] })
        }
        // setPhotos([...photos, photoUrl]);
    };
    const DelPhoto = () => {
        if (selectedDevice.device_photos) {
            const newPhotos = [...selectedDevice?.device_photos];
            newPhotos.splice(deletePhotoIndex, 1);
            setSelectedDevice({ ...selectedDevice, device_photos: newPhotos })
            setDeleteModal(false);
        }
    };

    const [progressVal2, setProgressVal2] = useState(101);

    const UploadDevicePhoto = async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const URL = API_URL + "/UploadDevicePhoto.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal2(percent);
            }
        }).then((response) => {
            AddPhoto(response.data.url);
            setProgressVal2(101);
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Fotoğraf yüklenirken hata oluştu.");
            }
            setProgressVal2(101);
        })
    }


    return (
        <div className="flex w-full flex-col items-center">
            <>
                <div className="w-72 mt-1 mb-5 ">
                    <Input
                        onPointerEnterCapture={() => { }}
                        onPointerLeaveCapture={() => { }}
                        error={error.device_seri_no}
                        crossOrigin="anonymous"
                        value={selectedDevice.device_seri_no}
                        onChange={(event) => {
                            setSelectedDevice({ ...selectedDevice, device_seri_no: event.target.value })
                            setError({ ...error, device_seri_no: event.target.value.trim() === "" })
                        }}
                        label="Seri No" />
                </div>
                <div className="w-72  mb-5 ">
                    <Input
                        onPointerEnterCapture={() => { }}
                        onPointerLeaveCapture={() => { }}
                        error={error.device_name}
                        crossOrigin="anonymous"
                        value={selectedDevice.device_name}
                        onChange={(event) => {
                            setSelectedDevice({ ...selectedDevice, device_name: event.target.value })
                            setError({ ...error, device_name: event.target.value.trim() === "" })
                        }}
                        label="Cihaz Adı" />
                </div>
                <div className="w-72  mb-5 ">
                    <Input
                        onPointerEnterCapture={() => { }}
                        onPointerLeaveCapture={() => { }}
                        value={selectedDevice.device_description}
                        crossOrigin="anonymous"
                        onChange={(event) => {
                            setSelectedDevice({ ...selectedDevice, device_description: event.target.value })
                        }}
                        label="Cihaz Açıklaması" />
                </div>
                {/* <div className="w-72 mb-5 flex flex-row ">
                    <div className="w-48">
                        <Input
    onPointerEnterCapture={() => {}}
    onPointerLeaveCapture={() => {}}
                            error={error.device_password}
                            value={selectedDevice.device_password}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, device_password: event.target.value })
                                setError({ ...error, device_password: event.target.value.trim() === "" })
                            }}
                            label="Cihaz Şifre"
                        />
                    </div>
                    <div className="ml-4 w-24">
                        <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                            onClick={() => {
                                setSelectedDevice({ ...selectedDevice, device_password: RandomPasswordGenerate() })
                                setError({ ...error, device_password: false })
                            }}
                        >Üret</Button>
                    </div>
                </div> */}
                <div className="mt-1 mb-4 w-72" >
                    <Input
                        onPointerEnterCapture={() => { }}
                        onPointerLeaveCapture={() => { }}
                        type={"number"}
                        crossOrigin="anonymous"
                        value={selectedDevice.device_height}
                        onChange={(event) => {
                            setSelectedDevice({ ...selectedDevice, device_height: parseInt((event.target.value)) })
                        }}
                        label="Cihaz Yüksekliği(mm)"
                    />
                </div>
{/*
                <div className="mt-1 mb-4 w-72" >
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        value={selectedDevice.coefficient_x_auto ? "Otomatik" : "Manuel"}
                        onChange={(item) => {
                            if (item && item.toString() === "Otomatik") {
                                setSelectedDevice({ ...selectedDevice, coefficient_x_auto: true, coefficient_x: undefined })
                            }
                            else {
                                setSelectedDevice({ ...selectedDevice, coefficient_x_auto: false })
                            }
                        }}
                        label="Roll Katsayı"
                    >
                        <Option key={"defX0"} value={"Manuel"} >{`Manuel`}</Option>
                        <Option key={"defX1"} value={"Otomatik"} >{`Otomatik`}</Option>
                    </Select>
                </div>
                {!selectedDevice.coefficient_x_auto &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={!selectedDevice.coefficient_x}
                            crossOrigin="anonymous"
                            type={"number"}
                            value={selectedDevice.coefficient_x}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, coefficient_x: (event.target.value) })
                            }}
                            label="Roll Katsayı Giriniz"
                        />
                    </div>
                }

                <div className="mt-1 mb-4 w-72" >
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        value={selectedDevice.coefficient_y_auto ? "Otomatik" : "Manuel"}
                        onChange={(item) => {
                            if (item && item.toString() === "Otomatik") {
                                setSelectedDevice({ ...selectedDevice, coefficient_y_auto: true, coefficient_y: undefined })
                            }
                            else {
                                setSelectedDevice({ ...selectedDevice, coefficient_y_auto: false })
                            }
                        }}
                        label="Pitch Katsayı"
                    >
                        <Option key={"defY0"} value={"Manuel"} >{`Manuel`}</Option>
                        <Option key={"defY1"} value={"Otomatik"} >{`Otomatik`}</Option>
                    </Select>
                </div>
                {!selectedDevice.coefficient_y_auto &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={!selectedDevice.coefficient_y}
                            crossOrigin="anonymous"
                            type={"number"}
                            value={selectedDevice.coefficient_y}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, coefficient_y: (event.target.value) })
                            }}
                            label="Pitch Katsayı Giriniz"
                        />
                    </div>
                }
                <div className="mt-1 mb-4 w-72" >
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        value={selectedDevice.coefficient_z_auto ? "Otomatik" : "Manuel"}
                        onChange={(item) => {
                            if (item && item.toString() === "Otomatik") {
                                setSelectedDevice({ ...selectedDevice, coefficient_z_auto: true, coefficient_z: undefined })
                            }
                            else {
                                setSelectedDevice({ ...selectedDevice, coefficient_z_auto: false })
                            }
                        }}
                        label="Yaw Katsayı"
                    >
                        <Option key={"defZ0"} value={"Manuel"} >{`Manuel`}</Option>
                        <Option key={"defZ1"} value={"Otomatik"} >{`Otomatik`}</Option>
                    </Select>
                </div>
                {!selectedDevice.coefficient_z_auto &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={!selectedDevice.coefficient_z}
                            crossOrigin="anonymous"
                            type={"number"}
                            value={selectedDevice.coefficient_z}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, coefficient_z: (event.target.value) })
                            }}
                            label="Yaw Katsayı Giriniz"
                        />
                    </div>
                }
*/}
                <div className="mt-4 mb-4 w-72" >
                    <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        value={selectedDevice.is_statik == "1" ? "Statik" : "Dinamik"}
                        onChange={(item) => {
                            if (item && item.toString() === "Statik") {
                                setSelectedDevice({ ...selectedDevice, is_statik: "1" })
                            }
                            else {
                                setSelectedDevice({ ...selectedDevice, is_statik: "0" })
                            }
                        }}
                        label="Cihaz Türü"
                    >
                        <Option key={"device_static"} value={"Statik"} >{`Statik`}</Option>
                        <Option key={"device_dynamic"} value={"Dinamik"} >{`Dinamik`}</Option>
                    </Select>
                </div>
                {selectedDevice.is_statik == "0" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={error.veri_gonderme_suresi}
                            crossOrigin="anonymous"
                            min={1}
                            max={60}
                            step={1}
                            type={"number"}
                            value={selectedDevice.veri_gonderme_suresi}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, veri_gonderme_suresi: parseInt((event.target.value)) })
                                if (event.target.value == "") {
                                    setError({ ...error, veri_gonderme_suresi: true })
                                }
                                else {
                                    setError({ ...error, veri_gonderme_suresi: parseInt((event.target.value)) < 1 || parseInt((event.target.value)) > 60 })
                                }
                            }}
                            label="Veri Gönderme Süresi (saniye) (Min:1-Max:60)"
                        />
                    </div>
                }
                {selectedDevice.is_statik == "1" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={error.veri_gonderme_suresi}
                            crossOrigin="anonymous"
                            min={600}
                            max={86400}
                            step={1}
                            type={"number"}
                            value={selectedDevice.veri_gonderme_suresi}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, veri_gonderme_suresi: parseInt((event.target.value)) })
                                if (event.target.value == "") {
                                    setError({ ...error, veri_gonderme_suresi: true })
                                }
                                else {
                                    setError({ ...error, veri_gonderme_suresi: parseInt((event.target.value)) < 600 || parseInt((event.target.value)) > 86400 })
                                }
                            }}
                            label="Veri Gönderme Süresi (saniye) (Min:600-Max:86400)"
                        />
                    </div>
                }      
                {selectedDevice.is_statik == "0" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={error.o_sayisi}
                            crossOrigin="anonymous"
                            min={1}
                            max={20}
                            type={"number"}
                            value={selectedDevice.o_sayisi}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, o_sayisi: parseInt((event.target.value)) })
                                if (event.target.value == "") {
                                    setError({ ...error, o_sayisi: true })
                                }
                                else {
                                    setError({ ...error, o_sayisi: parseInt((event.target.value)) < 1 || parseInt(event.target.value) > 20 });
                                }
                            }}
                            label="Frekans Hz (Min:1-Max:20)"
                        />
                    </div>
                }
                {selectedDevice.is_statik == "0" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={error.gyro_esik}
                            crossOrigin="anonymous"
                            min={0.001}
                            max={2.000}
                            onWheel={(e) => e.preventDefault()}
                            type={"number"}
                            value={selectedDevice.gyro_esik}
                            step={0.001}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, gyro_esik: parseFloat((event.target.value)) })
                                if (event.target.value == "") {
                                    setError({ ...error, gyro_esik: true })
                                }
                                else {
                                    setError({ ...error, gyro_esik: parseFloat((event.target.value)) < 0.001 || parseFloat(event.target.value) > 2.000 })
                                }
                            }}
                            label="İvme(g) Eşik Değeri (Min:0.001-Max:2)"
                        />
                    </div>
                }
                {selectedDevice.is_statik == "1" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Input
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                            error={error.gyro_esik}
                            crossOrigin="anonymous"
                            min={1}
                            onWheel={(e) => e.preventDefault()}
                            type={"number"}
                            value={selectedDevice.gyro_esik}
                            step={1}
                            onChange={(event) => {
                                setSelectedDevice({ ...selectedDevice, gyro_esik: parseInt((event.target.value)) })
                                if (event.target.value == "") {
                                    setError({ ...error, gyro_esik: true })
                                }
                                else {
                                    setError({ ...error, gyro_esik: parseInt((event.target.value)) < 1 })
                                }
                            }}
                            label="İnd. Sapma Eşik Değeri(mm) (Min:1)"
                        />
                    </div>
                }

                {selectedDevice.is_statik == "1" &&
                    <div className="mt-1 mb-4 w-72" >
                        <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                            value={selectedDevice.dynamic_type}
                            onChange={(item) => {
                                if (item && item.toString() === "none") {
                                    setSelectedDevice({ ...selectedDevice, dynamic_type: "none" });
                                } else if (item && item.toString() === "day") {
                                    setSelectedDevice({ ...selectedDevice, dynamic_type: "day" });
                                } else {
                                    setSelectedDevice({ ...selectedDevice, dynamic_type: "hour" });
                                }
                            }}
                            label="Veri Ortalama"
                        >
                            <Option key={"ortType1"} value={"none"} >{`Yok`}</Option>
                            <Option key={"ortType2"} value={"hour"} >{`Saatlik`}</Option>
                            <Option key={"ortType3"} value={"day"} >{`Günlük`}</Option>
                        </Select>
                    </div>
                }

                {selectedDevice.device_pdf_url === ""
                    ?
                    <>
                        <div className="flex flex-row  w-72  justify-between	">
                            <input
                                data-label="Pdf Seç"
                                className="file-input file-input-bordered file-input-md w-full max-w-xs"
                                ref={fileUploadRef}
                                type="file"
                                placeholder="Pdf yükle"
                                name="pdf"
                                accept="application/pdf"
                                onChange={(e: any) => {
                                    let files = e.target.files[0];
                                    setFile(files);
                                }} />


                        </div>
                        <div className="flex w-72 mt-2 justify-center	">
                            {
                                progressVal !== 100 &&
                                <Progress placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} value={progressVal} />
                            }
                        </div>
                        <div className="flex w-72 mt-2 justify-center">
                            {(selectedDevice.device_pdf_url === "")
                                ?
                                <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="red" variant="paragraph">Pdf dosyası eklenmemiştir.</Typography>
                                :
                                <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="green" variant="paragraph">Pdf dosyası eklenmiştir.</Typography>
                            }
                        </div>
                        {/* <div className="flex w-72 mt-1 justify-center">
                            <Button placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}
                                style={{
                                }}
                                disabled={buttonDisabled}
                                onClick={() => {
                                    UpdateOrCreateDeviceFile();
                                }} >Pdf Dosyasını Yükle</Button>
                        </div> */}
                    </>
                    :
                    <>
                        <div className="flex w-72 justify-center">
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} color="green" variant="h6">Pdf dosyası vardır.</Typography>
                        </div>
                        <div className="flex w-72 mt-1 justify-center">
                            <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                style={{
                                }}
                                onClick={() => {
                                    setSelectedDevice({ ...selectedDevice, device_pdf_url: "" });
                                }} >Pdf Dosyasını Sil</Button>
                        </div>
                    </>
                }
                {/* <div className="flex w-72 mt-2 justify-center">
                    <Typography placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} color="red" variant="small">Pdf dosyasında yapılan değişiklikler güncelle'ye basamadığınız sürece değişmez.</Typography>
                </div> */}

                <>
                    {//Fotoğraflar
                        (selectedDevice?.device_photos?.length != 0 && selectedDevice?.device_photos) ?
                            <p>Cihaza ait fotoğraflar</p>
                            :
                            <p>Cihaza ait fotoğraf bulunmamaktadır.</p>
                    }
                    <div className="grid grid-cols-5 	">
                        {
                            selectedDevice.device_photos &&
                            <>
                                {
                                    selectedDevice.device_photos.map((item, index) => {
                                        return (
                                            <div style={{ position: "relative" }}>
                                                <a href={item} target="_blank">
                                                    <img
                                                        onClick={() => {
                                                        }}
                                                        style={{
                                                            margin: 5,
                                                            height: 45,
                                                            width: 45,
                                                            border: "1px solid gray",
                                                            borderRadius: 10,
                                                            cursor: "zoom-in",
                                                        }} src={item} />
                                                </a>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        height: 20,
                                                        width: 20,
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            setDeleteModal(true);
                                                            setDeletePhotoIndex(index);
                                                        }
                                                        }
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="red" className="w-5 h-5">
                                                            <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </>
                        }
                        {((selectedDevice.device_photos && selectedDevice.device_photos.length < 5) || (!selectedDevice.device_photos)) &&
                            <button
                                disabled={progressVal2 !== 101}
                                onClick={() => fileUploadRef.current.click()}
                                style={{
                                    height: 55,
                                    width: 55,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: progressVal2 !== 101 ? "not-allowed" : undefined
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={progressVal2 !== 101 ? "gray" : "black"}
                                    style={{
                                        height: 45,
                                        width: 45,
                                    }}>
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                </svg>
                            </button>
                        }
                    </div>
                    {deleteModal &&
                        <DeleteModal
                            title={"Fotoğraf Silinsin Mi"}
                            description={"Seçmiş olduğunuz fotoğraf silinsin mi? Güncelle butonuna tıklamadığınız sürece güncellenmez."}
                            onCancel={() => setDeleteModal(false)}
                            onOk={() => DelPhoto()}
                        />
                    }
                    <input
                        style={{ display: "none" }}
                        ref={fileUploadRef}
                        type="file"
                        placeholder="Resim yükle"
                        name="image"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        onChange={(e: any) => {
                            let files = e.target.files[0];
                            UploadDevicePhoto(files);
                        }} />
                    <div className="flex w-72 mt-2 justify-center	">
                        {
                            progressVal2 !== 101 &&
                            <Progress placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} value={progressVal2 - 3} />
                        }
                    </div>
                </>
                <div className="w-72 mt-5 mb-5 flex flex-row justify-around">
                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        color="red"
                        onClick={() => {
                            props.onCancel();
                        }}
                    >İptal</Button>
                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                        disabled={(progressVal2 != 101) || (progressVal != 100)}
                        color="green"
                        onClick={() => {
                            DeviceUpdate();
                        }}
                    >Güncelle</Button>
                </div>
            </>
        </div>
    )
}
export default DeviceUpdateModal;
