import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import { Chart as ChartJS } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from "moment";
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';

// Register the zoom plugin
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

interface Props {
  data: IUserDeviceData[];
  height: number;
  statik: "0" | "1"
}



interface ChartRefType {
  resetZoom: () => void;
}

const DefAngleChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef
  const chartRef2 = useRef<ChartRefType | null>(null); // Specify the type of chartRef


  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const resetZoom2 = () => {
    if (chartRef2 && chartRef2.current) {
      chartRef2.current.resetZoom();
    }
  };

  let datasests = [];
  let datasests2 = [];

  if (chartData[0].refined_deflection) {
    datasests2.push(
      {
        label: 'İndirgenmiş Sapma (mm)',
        data: chartData.map((item) => item.refined_deflection),
        borderColor: CHART_COLORS.purple,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].refined_roll_mm) {
    datasests2.push(
      {
        label: 'İndirgenmiş Roll (mm)',
        data: chartData.map((item) => item.refined_roll_mm),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].refined_pitch_mm) {
    datasests2.push(
      {
        label: 'İndirgenmiş Pitch (mm)',
        data: chartData.map((item) => item.refined_pitch_mm),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].refined_angle_x) {
    datasests.push(
      {
        label: 'İndirgenmiş Roll (°)',
        data: chartData.map((item) => item.refined_angle_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].refined_angle_y) {
    datasests.push(
      {
        label: 'İndirgenmiş Pitch (°)',
        data: chartData.map((item) => item.refined_angle_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].refined_angle_z) {
    datasests.push(
      {
        label: 'İndirgenmiş Yaw (°)',
        data: chartData.map((item) => item.refined_angle_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  return (
    <>
      {chartData.length > 0 && (chartData[0].refined_deflection || chartData[0].refined_roll_mm || chartData[0].refined_pitch_mm) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '90%', height: '50vh' }}>
          <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'İndirgenmiş Sapma Grafiği'
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    title: {display: true,text: 'Zaman'},
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                    title: {display: true,text: 'İnd. Eğim (mm)'},
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests2
            }}
          />
        </div>
      )}
      {chartData.length > 0 && (chartData[0].refined_angle_x || chartData[0].refined_angle_y || chartData[0].refined_angle_z) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '90%', height: '50vh' }}>
          <button onClick={resetZoom2} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
          <LineCharJS
            ref={(ref) => {
              if (ref) {
                chartRef2.current = ref;
              }
            }}
            options={
              {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                    text: 'İndirgenmiş Eğim Grafiği'
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    },
                  },
                },
                scales: {
                  x: {
                    display: true,
                    title: {display: true,text: 'Zaman'},
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        if (statik == "1") {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                        } else {
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                        }
                      }
                    }
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: 'left',
                    title: {display: true,text: 'Eğim (°)'},
                  },
                }
              }
            }
            data={{
              labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
              datasets: datasests
            }}
          />
        </div>
      )}
    </>
  )
}

export default DefAngleChartJS;
