import { Button, Select, Option, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IDevice } from "../../../models/AdminPanel";
import TableComponentAdmin from "./TableComponentAdmin";
import { IAdminDeviceData, IAdminDeviceInfo } from "../../../models/Response";
import DeleteModal from "../../../components/DeleteModal";
import { Link } from "react-router-dom";
import { LinkIcon } from "@heroicons/react/24/solid";
import { DBToDateText } from "../../../utils/DBToDateText";
import styles from "../../../resources/styles/Th.module.css";
import SettingsModal from "../components/SettingsModal";
import ExcelExportNew from "./ExcelExportNew";
import moment from "moment";
import AccChartJS from "../../UserPanel/chart-js/AccChartJS";
import AngleChartJS from "../../UserPanel/chart-js/AngleChartJS";
import DefAngleChartJS from "../../UserPanel/chart-js/DefAngleChartJS";
import GyroChartJS from "../../UserPanel/chart-js/GyroChartJS";
import MagChartJS from "../../UserPanel/chart-js/MagChartJS";
import MillisChartJS from "../../UserPanel/chart-js/MillisChartJS";
import BatteryChartJS from "../../UserPanel/chart-js/BatteryChartJS";
import CustomDate from "../../../components/CustomDate";
import Loading from "../../../components/Loading";


const DeviceDatas = () => {

    const [deviceDatas, setDeviceDatas] = useState<IAdminDeviceData[]>();
    const [devices, setDevices] = useState<IDevice[]>();
    const GetDevices = () => {
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
                if (selectedDeviceId !== "") {
                    const device = response.data?.data?.find((i: any) => i?.device_id.toString() == selectedDeviceId);
                    if (device) {
                        setSelectedDevice(device);
                    }
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    useEffect(() => {
        GetDevices();
    }, [])

    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [selectedDevice, setSelectedDevice] = useState<IDevice>();



    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined;

    const [loading, setLoading] = useState(false);
    const GetAdminDeviceDatas = () => {
        setLoading(true);
        API.getInstance().GetAdminDeviceDatas(selectedDeviceId, timeRangeSelect?.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceDatas(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setLoading(false))
    }

    const GetAdminDeviceInfo = () => {
        API.getInstance().GetAdminDeviceInfo(selectedDeviceId, timeRangeSelect?.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceInfo(response.data.data);
                setDeviceHeight(response.data.data.device_height);
                setDeviceStatic(response.data.data.is_statik);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const DeleteAdminDeviceData = (id: string) => {
        API.getInstance().DeleteAdminDeviceData(id)
            .then((response) => {
                toast.success(response.data.message);
                GetAdminDeviceDatas();
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setDataItemModalId(""))
    }
    const DeleteAdminDeviceDatas = () => {
        API.getInstance().DeleteAdminDeviceDatas(selectedDeviceId)
            .then((response) => {
                toast.success(response.data.message);
                GetAdminDeviceDatas();
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setModalShow(false))
    }

    const calculateHeight = () => {
        const h1 = document.getElementById("table1")?.offsetHeight
        const h2 = document.getElementById("table2")?.offsetHeight
        const h3 = document.getElementById("table3")?.offsetHeight
        if (h1 && h2 && h3) {
            return Math.max(h1, h2, h3);
        }
        return undefined;
    }


    const [deviceInfo, setDeviceInfo] = useState<IAdminDeviceInfo>();
    const [modalShow, setModalShow] = useState(false);
    const [settingsModalShow, setSettingsModalShow] = useState(false);
    const [dataItemModalId, setDataItemModalId] = useState("");
    const [tableHeight, setTableHeight] = useState<number | undefined>(calculateHeight())

    useEffect(() => {
        setTimeout(() => {
            setTableHeight(calculateHeight())
        }, 200);
    }, [deviceInfo])

    const [activeTabIndex, setActiveTagIndex] = useState(-1);
    const [deviceHeight, setDeviceHeight] = useState<number>(0);
    const [deviceStatic, setDeviceStatic] = useState<"0" | "1">("0");

    const Refresh = () => {
        GetAdminDeviceDatas();
        GetAdminDeviceInfo();
    }
    const status = deviceInfo?.last_date ? moment(deviceInfo.last_date).isAfter(moment().subtract((parseInt(deviceInfo.veri_gonderme_suresi) * 2) + 600, "second")) : undefined;
    const timeRange = [
        {
            "label": "Son 10 Dakika",
            "value": "-10 minutes"
        },
        {
            "label": "Son 30 Dakika",
            "value": "-30 minutes"
        },
        {
            "label": "Son 1 Saat",
            "value": "-1 hour"
        },
        {
            "label": "Son 3 Saat",
            "value": "-3 hour"
        },
        {
            "label": "Son 6 Saat",
            "value": "-6 hour"
        },
        {
            "label": "Son 12 Saat",
            "value": "-12 hour"
        },
        {
            "label": "Son 1 Gün",
            "value": "-1 day"
        },
        {
            "label": "Son 2 Gün",
            "value": "-2 day"
        },
        {
            "label": "Özel Tarih Aralığı",
            "value": "custom"
        },
        // {
        //     "label": "Tüm Zamanlar",
        //     "value": "all"
        // },

    ]
    const [timeRangeSelect, setTimeRange] = useState<{ "label": string, "value": string }>();

    useEffect(() => {
        if (startDate) {
            setEndDate(null);
        }
    }, [startDate]);

    return (
        <>
            <div className="flex w-full flex-col items-center">
                <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="text-center mt-5" color="black" variant="h4">Verisini görmek istediğiniz cihazı seçiniz</Typography>
                <div className="mt-2 mb-4 w-72" >
                    {devices &&
                        <div className="flex w-full flex-col items-center ">
                            <Select
                                onPointerEnterCapture={() => { }}
                                onPointerLeaveCapture={() => { }}
                                placeholder=""
                                disabled={devices.length === 0}
                                value={selectedDeviceId}
                                onChange={(item) => {
                                    setSelectedDeviceId(item ? item.toString() : "")
                                    const device = devices.find((i) => i.device_id == item?.toString())!;
                                    setSelectedDevice(device);
                                }}
                                label={devices.length === 0 ? "Cihaz bulunamamıştır." : "Cihaz seçiniz"}>
                                {devices.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.device_id?.toString()} >{`ID:${item.device_id} SN:${item.device_seri_no}`}</Option>
                                    )
                                })
                                }
                            </Select>
                            {selectedDevice && selectedDevice.is_statik == "0" &&
                                <div className="mt-3 w-72">
                                    <Select
                                        onPointerEnterCapture={() => { }}
                                        onPointerLeaveCapture={() => { }}
                                        placeholder=""
                                        disabled={devices.length === 0}
                                        value={timeRangeSelect?.value}
                                        onChange={(item) => {
                                            setTimeRange(timeRange.find((i) => i.value === item?.toString())!);
                                        }}
                                        label={"Tarih aralığı seçiniz."}
                                    >
                                        {
                                            timeRange.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.value} >{item.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            }
                            {
                                timeRangeSelect?.value === "custom" &&
                                <div className="flex flex-row ">
                                    <CustomDate
                                        maxDate={moment().toDate()}
                                        date={startDate}
                                        setDate={setStartDate}
                                        title="Başlangıç Tarihi"
                                    />
                                    <CustomDate
                                        minDate={startDate}
                                        date={endDate}
                                        // maxDate={moment(startDate).add(4, "days").toDate()}
                                        // max start date den 5 fazla olcak ama bugünü geçmeyecek
                                        maxDate={moment().isAfter(moment(startDate).add(2, "days")) ? moment(startDate).add(2, "days").toDate() : moment().toDate()}
                                        setDate={setEndDate}
                                        title="Bitiş Tarihi"
                                    />
                                </div>
                            }
                            <Button
                                onPointerEnterCapture={() => { }}
                                onPointerLeaveCapture={() => { }}
                                placeholder=""
                                className="mt-3"
                                disabled={selectedDevice ? (selectedDevice.is_statik == "1" ? false : (timeRangeSelect && timeRangeSelect?.value == "custom" ? (startDate == null || endDate == null) : !timeRangeSelect)) : true}
                                onClick={() => {
                                    GetAdminDeviceDatas();
                                    GetAdminDeviceInfo();
                                }}
                            >Bilgilerini Getir</Button>
                        </div>
                    }
                </div>
            </div>
            {
                deviceDatas &&
                <>
                    {deviceInfo &&
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full" style={{ justifyItems: "center" }} >

                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table1" className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                    <tbody>
                                        <tr>
                                            <th className={styles.th} >Cihaz Id</th>
                                            <td className={styles.td}>{deviceInfo?.device_id}</td>
                                        </tr>
                                        <tr>
                                            <th className={styles.th}>Seri No</th>
                                            <td className={styles.td}>{deviceInfo.device_seri_no}</td>
                                        </tr>
                                        <tr>
                                            <th className={styles.th}>Cihaz İsmi</th>
                                            <td className={styles.td}>{deviceInfo.device_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table2" className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                    <tbody>
                                        <tr >
                                            <th className={styles.th}>Cihaz Açıklama</th>
                                            <td className={styles.td}><p className={styles.td}
                                                style={{
                                                    whiteSpace: "normal",
                                                }}>{deviceInfo.device_description == "" ? "Açıklama girilmemiştir." : deviceInfo.device_description}</p></td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Cihaz Fotoğrafı</th>
                                            <td className={styles.td}>
                                                {(deviceInfo.device_photos && deviceInfo.device_photos.length > 0) ?
                                                    <div className="flex flex-row">
                                                        {deviceInfo?.device_photos?.map((item) => {
                                                            return (
                                                                <a href={item} target="_blank">
                                                                    <img
                                                                        onClick={() => {
                                                                        }}
                                                                        style={{
                                                                            margin: 5,
                                                                            height: 25,
                                                                            width: 25,
                                                                            border: "1px solid gray",
                                                                            borderRadius: 5,
                                                                            cursor: "zoom-in",
                                                                        }} src={item} />
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    "Fotoğraf Yüklenmemiştir."
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table id="table3" className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                    <tbody>
                                        <tr >
                                            <th className={styles.th}>Cihaz Yönetimi</th>
                                            <td className={styles.td}>
                                                {deviceInfo.relay_error &&
                                                    <p className={styles.th}>
                                                        {"Kalibrasyon Tarihi"}
                                                        <br></br>
                                                        {DBToDateText(deviceInfo.relay_error_date)}
                                                    </p>
                                                }
                                                {deviceInfo.sensor_error &&
                                                    <p className={styles.th}>
                                                        {"Sensör Okuma Hatası"}
                                                        <br></br>
                                                        {DBToDateText(deviceInfo.sensor_error_date)}
                                                    </p>
                                                }
                                                {(!deviceInfo.relay_error && !deviceInfo.sensor_error) && "Kayıt Yok"}
                                            </td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Cihaz Yükseklik</th>
                                            <td className={styles.td}>{deviceInfo.device_height} mm </td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Kalibrasyon Belgesi</th>
                                            <td className={styles.td}>
                                                {
                                                    deviceInfo.device_pdf_url === "" ?
                                                        "Bulunmamaktadır"
                                                        :
                                                        <Link className="flex flex-row items-center  " target="_blank" to={deviceInfo?.device_pdf_url}>
                                                            <LinkIcon color="#2196f3" height={25} />
                                                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="ml-1" color="blue" variant="h6">Görüntüle</Typography>
                                                        </Link>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                    <tbody>
                                        {status != undefined &&
                                            <tr >
                                                <th className={styles.th}>Cihaz Statüsü</th>
                                                <th className={styles.td} style={{ color: status ? "#66bb6a" : "red" }}>{status ? "ONLINE" : "OFFLINE"}</th>
                                            </tr>}
                                        <tr >
                                            <th className={styles.th}>Cihaz Türü</th>
                                            <td className={styles.td}>{deviceInfo.is_statik == "1" ? "Statik" : "Dinamik"}</td>
                                        </tr>
                                        {deviceInfo.is_statik == "1" &&
                                            <tr>
                                                <th className={styles.th}>Veri Ortalama</th>
                                                <td className={styles.td}>
                                                    {deviceInfo.dynamic_type === "none" && "Seçenek Yok"}
                                                    {deviceInfo.dynamic_type === "hour" && "Saat"}
                                                    {deviceInfo.dynamic_type === "day" && "Gün"}
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                <table className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                    <tbody>
                                        <tr >
                                            <th className={styles.th}>Regresyon Roll</th>
                                            <td className={styles.td}>{`${deviceInfo.coefficient_x} ${deviceInfo.coefficient_x_auto ? "Otomatik" : "Manuel"}`}</td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Regresyon Pitch</th>
                                            <td className={styles.td}>{`${deviceInfo.coefficient_y} ${deviceInfo.coefficient_y_auto ? "Otomatik" : "Manuel"}`}</td>
                                        </tr>
                                        <tr >
                                            <th className={styles.th}>Regresyon Yaw</th>
                                            <td className={styles.td}>{`${deviceInfo.coefficient_z} ${deviceInfo.coefficient_z_auto ? "Otomatik" : "Manuel"}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {(deviceDatas.length > 0 || deviceInfo.first_date || deviceInfo.last_date) &&
                                <div className="overflow-x-auto  mt-5 mb-5 shadow border-gray-300" style={{ width: "350px" }}>
                                    <table className="table" style={{ width: "350px", height: tableHeight && tableHeight }}>
                                        <tbody>
                                            {
                                                deviceDatas.length > 0 &&
                                                <tr >
                                                    <th className={styles.th}>Veri Sayısı</th>
                                                    <td className={styles.td}>{deviceDatas.length}</td>
                                                </tr>
                                            }
                                            {deviceInfo.first_date &&
                                                <tr >
                                                    <th className={styles.th}>İlk Veri Tarihi</th>
                                                    <td className={styles.td}>{DBToDateText(deviceInfo.first_date)}</td>
                                                </tr>
                                            }
                                            {deviceInfo.last_date &&
                                                <tr >
                                                    <th className={styles.th}>Son Veri Tarihi</th>
                                                    <td className={styles.td}>{DBToDateText(deviceInfo.last_date)}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    }
                    {
                        (settingsModalShow && selectedDevice) &&
                        <SettingsModal
                            selectedDevice={selectedDevice}
                            onCancel={() =>
                                setSettingsModalShow(false)
                            }
                            onUpdated={() => {
                                GetDevices();
                                GetAdminDeviceInfo();
                                setSettingsModalShow(false)
                            }}
                        />
                    }
                    {
                        modalShow &&
                        <DeleteModal
                            title={"Verileri Sil"}
                            description={"Cihazınıza ait bütün verileri silmek istiyor musunz?"}
                            onCancel={() =>
                                setModalShow(false)
                            }
                            onOk={() => {
                                DeleteAdminDeviceDatas();
                            }
                            } />
                    }
                    {
                        dataItemModalId !== "" &&
                        <DeleteModal
                            title={"Veriyi Sil"}
                            description={"Seçmiş olduğunuz veri silinecektir."}
                            onCancel={() =>
                                setDataItemModalId("")
                            }
                            onOk={() => {
                                DeleteAdminDeviceData(dataItemModalId);
                            }
                            } />
                    }
                    <>
                        <div className="flex justify-center ">
                            <div className="w-fit flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end tabs" style={{ maxWidth: 1400 }}>
                                <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                    <ExcelExportNew seriNo={deviceInfo?.device_seri_no} data={deviceDatas} dataList={undefined} />
                                </div>
                                <div className=" flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                        className="mr-3"
                                        color="orange"
                                        onClick={() => setSettingsModalShow(true)}
                                    >Ayarlar</Button>
                                </div>
                                <div className="flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                        color="red"
                                        onClick={() => setModalShow(true)}
                                    >Tüm Verileri Sil</Button>
                                </div>
                                <div className="flex mx-3 mt-3 sm:mt-0 justify-center sm:justify-end ">
                                    <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                        color="green"
                                        onClick={() => Refresh()}
                                    >Verileri Yenile</Button>
                                </div>
                            </div>
                        </div>
                    </>
                </>
            }
            <>
                {
                    loading ?
                        <Loading /> :
                        deviceDatas &&
                            deviceDatas.length !== 0 ?
                            <>
                                <div className="flex justify-center">
                                    <div className="w-fit	justify-center mt-3 tabs tabs-boxed ">
                                        {(deviceDatas[0].acc_x || deviceDatas[0].acc_y || deviceDatas[0].acc_z) && <a onClick={() => setActiveTagIndex(0)} className={`tab tab-lg ${activeTabIndex === 0 ? "tab-active" : ""}`}>İvme Grafiği</a>}
                                        {(deviceDatas[0].angle_x || deviceDatas[0].angle_y || deviceDatas[0].angle_z || deviceDatas[0].deflection || deviceDatas[0].temp) && <a onClick={() => setActiveTagIndex(1)} className={`tab tab-lg ${activeTabIndex === 1 ? "tab-active" : ""}`}>Eğim Grafiği </a>}
                                        {(deviceDatas[0].refined_angle_x || deviceDatas[0].refined_angle_y || deviceDatas[0].refined_angle_z || deviceDatas[0].refined_deflection) && <a onClick={() => setActiveTagIndex(2)} className={`tab tab-lg ${activeTabIndex === 2 ? "tab-active" : ""}`}>İnd. Eğim Grafiği </a>}
                                        {(deviceDatas[0].gyro_x || deviceDatas[0].gyro_y || deviceDatas[0].gyro_z) && <a onClick={() => setActiveTagIndex(3)} className={`tab tab-lg ${activeTabIndex === 3 ? "tab-active" : ""}`}>Açısal Hız Grafiği </a>}
                                        {(deviceDatas[0].mag_x || deviceDatas[0].mag_y || deviceDatas[0].mag_z) && <a onClick={() => setActiveTagIndex(4)} className={`tab tab-lg ${activeTabIndex === 4 ? "tab-active" : ""}`}>Manyetometre Grafiği </a>}
                                        {(deviceDatas[0].millis) && <a onClick={() => setActiveTagIndex(5)} className={`tab tab-lg ${activeTabIndex === 5 ? "tab-active" : ""}`}>VGS Grafiği </a>}
                                        {(deviceDatas[0].battery_voltage) && <a onClick={() => setActiveTagIndex(6)} className={`tab tab-lg ${activeTabIndex === 6 ? "tab-active" : ""}`}>Batarya Grafiği </a>}
                                        <a onClick={() => setActiveTagIndex(7)} className={`tab tab-lg ${activeTabIndex === 7 ? "tab-active" : ""}`}>Data</a>
                                    </div>
                                </div>
                                {activeTabIndex === 0 ?
                                    // <AccChart data={deviceDatas} />
                                    <AccChartJS data={deviceDatas} statik={deviceStatic} />
                                    : activeTabIndex === 1 ?
                                        // <AngleChart data={deviceDatas} height={deviceHeight} />
                                        <AngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                        :
                                        activeTabIndex === 2 ?
                                            // <DefAngleChart data={deviceDatas} height={deviceHeight} />
                                            <DefAngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                            :
                                            activeTabIndex === 3 ?
                                                // <GyroChart data={deviceDatas} />
                                                <GyroChartJS data={deviceDatas} statik={deviceStatic} />
                                                :
                                                activeTabIndex === 4 ?
                                                    // <MagChart data={deviceDatas} />
                                                    <MagChartJS data={deviceDatas} statik={deviceStatic} />
                                                    :
                                                    activeTabIndex === 5 ?
                                                        // <MillisChart data={deviceDatas} />
                                                        <MillisChartJS data={deviceDatas} statik={deviceStatic} />
                                                        :
                                                        activeTabIndex === 6 ?
                                                            // <BatteryChart data={deviceDatas} height={deviceHeight} />
                                                            <BatteryChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                                            :
                                                            activeTabIndex === 7 ?
                                                                <TableComponentAdmin
                                                                    deviceDatas={deviceDatas}
                                                                    setDeletedDataId={(id) => {
                                                                        setDataItemModalId(id);
                                                                    }}
                                                                />
                                                                :
                                                                null
                                }
                            </>
                            :
                            <Typography placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} className="text-center mt-5 mb-5" color="red" variant="h4">Cihaza ait veri bulunmamaktadır.</Typography>

                }
            </>
        </>


    )
}
export default DeviceDatas;
