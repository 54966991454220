import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS, Bar } from 'react-chartjs-2';
import moment from "moment";
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';

// Register the zoom plugin
Chart.register(zoomPlugin);

interface Props {
  data: IUserDeviceData[];
  statik: "0" | "1"
}


interface ChartRefType {
  resetZoom: () => void;
}

const MillisChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef

  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  let datasests = [];



  if (statik == "1") {
    if (chartData[0].millis) {
      datasests.push(
        {
          label: 'Veri Gönderme Süresi(s)',
          data: chartData.map((item) => item.millis),
          borderColor: CHART_COLORS.blue,
          borderWidth: 2,
          pointRadius: 0,
          yAxisID: 'y',
        }
      )
    }
  } else {
    if (chartData[0].millis) {
      datasests.push(
        {
          label: 'Hareket Algılama',
          data: chartData.map((item) => item.millis),
          borderColor: CHART_COLORS.blue,
          borderWidth: 3,
          pointRadius: 0,
          yAxisID: 'y',
        }
      )
    }
  }

  return (
<>
  {(chartData.length > 0 && (chartData[0].millis)) && (
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: '90%', height: '50vh' }}>
      <button onClick={resetZoom} style={{ border: '2px solid black', padding: '1px', borderRadius: '5px' }}>Zoom Reset</button>
      
      {parseInt(statik, 10) === 1 ? (
        <Bar
          ref={(ref) => {
            if (ref) {
              chartRef.current = ref;
            }
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            plugins: {
              title: {
                display: false,
                text: 'Veri Gönderme Süresi Grafiği'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'xy',
                },
                zoom: {
                  mode: 'xy',
                  wheel: { enabled: true },
                  pinch: { enabled: true },
                },
              },
            },
            scales: {
              x: {
                display: true,
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                          return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                      }
                    }
              },
              y: {
                type: "linear",
                display: true,
                position: 'left',
              },
            }
          }}
          data={{
            labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
            datasets: datasests
          }}
        />
      ) : (
        <LineCharJS
          ref={(ref) => {
            if (ref) {
              chartRef.current = ref;
            }
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            plugins: {
              title: {
                display: false,
                text: 'Hareket Algılama Grafiği'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'xy',
                },
                zoom: {
                  mode: 'xy',
                  wheel: { enabled: true },
                  pinch: { enabled: true },
                },
              },
            },
            scales: {
              x: {
                display: true,
                    ticks: {
                      callback: function (value, index, ticks) {
                        const date = this.getLabelForValue(value as number)
                        return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                      }
                    }
              },
              y: {
                type: "linear",
                display: true,
                position: 'left',
              },
            }
          }}
          data={{
            labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
            datasets: datasests
          }}
        />
      )}
    </div>
  )}
</>
  )
}

export default MillisChartJS;
