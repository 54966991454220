import { FC, useRef } from "react"
import { IPhotoUploadResponse, IUpdateDeviceUserInfo } from "../../../models/Response";
import { Alert, Button, Input, Progress, Select, Option } from "@material-tailwind/react";
import { useState } from "react";
import API from "../../../API/API";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "../../../common/AppSettings";
import DeleteModal from "../../../components/DeleteModal";
interface Props {
    onCancel: () => void
    device: IUpdateDeviceUserInfo
    onUpdated: () => void
}
const SettingsModalUser: FC<Props> = (props) => {
    const { onUpdated, onCancel } = props;
    const [device, setDevice] = useState<IUpdateDeviceUserInfo>(props.device);
    const [error, setError] = useState({
        device_name: false,
        device_height: false,
        veri_gonderme_suresi: false,
        o_sayisi: false,
        gyro_esik: false
    });

    const DeviceUpdate = async () => {
        if (device?.device_name.trim() === "" || Number.isNaN(device.device_height) || (device.veri_gonderme_suresi < 1 || device.veri_gonderme_suresi > 86400)) {
            setError({
                device_name: device?.device_name.trim() === "",
                device_height: Number.isNaN(device.device_height),
                veri_gonderme_suresi: (device.veri_gonderme_suresi < 1 || device.veri_gonderme_suresi > 86400),
                o_sayisi: device.o_sayisi < 1 || device.o_sayisi > 20,
                gyro_esik: device.gyro_esik < 0.001
            });
        } else {
            setError({
                device_name: false,
                device_height: false,
                veri_gonderme_suresi: false,
                o_sayisi: false,
                gyro_esik: false,
            });
            UpdateDevice();
        }
    }
    const UpdateDevice = () => {
        API.getInstance().UpdateDeviceUser(device)
            .then((response) => {
                toast.success(response.data.message);
                onUpdated();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [progressVal, setProgressVal] = useState(101);
    const fileUploadRef = useRef<any>();
    const AddPhoto = (photoUrl: string) => {
        setDevice({ ...device, device_photos: [...device.device_photos, photoUrl] })
    };
    const DelPhoto = () => {
        const newPhotos = [...device.device_photos];
        newPhotos.splice(deletePhotoIndex, 1);
        setDevice({ ...device, device_photos: newPhotos })
        setDeleteModal(false);
    };

    const UploadDevicePhoto = async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const URL = API_URL + "/UploadDevicePhoto.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            AddPhoto(response.data.url);
            setProgressVal(101);
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Fotoğraf yüklenirken hata oluştu.");
            }
            setProgressVal(101);
        })
    }

    const [deleteModal, setDeleteModal] = useState(false);
    const [deletePhotoIndex, setDeletePhotoIndex] = useState(0);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-sm">
                    <div className="border-0 rounded-lg shadow-lg relative flex items-center flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 pb-2 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">Ayarlar</h3>
                        </div>
                        <div style={{ overflowY: "auto", padding: 20 }}>
                            <div className="flex w-full flex-col items-center">
                                <>
                                    <div className="w-72 mt-1 mb-5 ">
                                        <Input
                                            onPointerEnterCapture={() => { }}
                                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                            disabled
                                            value={"Seri No : " + device.device_seri_no}
                                        />
                                    </div>
                                    <div className="w-72  mb-5 ">
                                        <Input
                                            onPointerEnterCapture={() => { }}
                                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                            error={error.device_name}
                                            value={device.device_name}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_name: event.target.value })
                                                setError({ ...error, device_name: event.target.value.trim() === "" })
                                            }}
                                            label="Cihaz Adı" />
                                    </div>
                                    <div className="w-72  mb-5 ">
                                        <Input
                                            onPointerEnterCapture={() => { }}
                                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                            value={device.device_description}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_description: event.target.value })
                                            }}
                                            label="Cihaz Açıklaması" />
                                    </div>
                                    <div className="mt-1 mb-4 w-72" >
                                        <Input
                                            onPointerEnterCapture={() => { }}
                                            onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                            type={"number"}
                                            value={device.device_height}
                                            error={error.device_height}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_height: parseInt((event.target.value)) })
                                                setError({ ...error, device_height: Number.isNaN(parseInt((event.target.value))) })
                                            }}
                                            label="Cihaz Yüksekliği(mm)"
                                        />
                                    </div>

                                    <div className="mt-4 mb-4 w-72" >
                                        <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            value={device.is_statik == "1" ? "Statik" : "Dinamik"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Statik") {
                                                    setDevice({ ...device, is_statik: "1" })
                                                }
                                                else {
                                                    setDevice({ ...device, is_statik: "0" })
                                                }
                                            }}
                                            label="Cihaz Türü"
                                        >
                                            <Option key={"device_static"} value={"Statik"} >{`Statik`}</Option>
                                            <Option key={"device_dynamic"} value={"Dinamik"} >{`Dinamik`}</Option>
                                        </Select>
                                    </div>

                                    {device.is_statik == "1" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Input
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                                error={error.veri_gonderme_suresi}
                                                min={600}
                                                max={86400}
                                                step={1}
                                                type={"number"}
                                                value={device.veri_gonderme_suresi}
                                                onChange={(event) => {
                                                    setDevice({ ...device, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    // setSelectedDevice({ ...selectedDevice, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, veri_gonderme_suresi: true })
                                                    }
                                                    else {
                                                        setError({ ...error, veri_gonderme_suresi: (parseInt((event.target.value)) < 600 || parseInt((event.target.value)) > 86400) })
                                                    }
                                                }}
                                                label= "Veri Gönderme Süresi (saniye) (Min:600-Max:86400)"
                                            />
                                        </div>
                                    }
                                    {device.is_statik == "0" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Input
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }} crossOrigin="anonymous"
                                                error={error.veri_gonderme_suresi}
                                                min={1}
                                                max={60}
                                                step={1}
                                                type={"number"}
                                                value={device.veri_gonderme_suresi}
                                                onChange={(event) => {
                                                    setDevice({ ...device, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    // setSelectedDevice({ ...selectedDevice, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, veri_gonderme_suresi: true })
                                                    }
                                                    else {
                                                        setError({ ...error, veri_gonderme_suresi: (parseInt((event.target.value)) < 1 || parseInt((event.target.value)) > 60) })
                                                    }
                                                }}
                                                label= "Veri Gönderme Süresi (saniye) (Min:1-Max:60)"
                                            />
                                        </div>
                                    }
                                    {device.is_statik == "0" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Input
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }}
                                                error={error.o_sayisi}
                                                crossOrigin="anonymous"
                                                min={1}
                                                max={20}
                                                type={"number"}
                                                value={device.o_sayisi}
                                                onChange={(event) => {
                                                    setDevice({ ...device, o_sayisi: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, o_sayisi: true })
                                                    }
                                                    else {
                                                        setError({ ...error, o_sayisi: parseInt((event.target.value)) < 1 || parseInt(event.target.value) > 20 });
                                                    }
                                                }}
                                                label="Frekans Hz (Min:1-Max:20)"
                                            />
                                        </div>
                                    }
                                    {device.is_statik == "0" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Input
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }}
                                                error={error.gyro_esik}
                                                crossOrigin="anonymous"
                                                min={0.001}
                                                max={2.000}
                                                onWheel={(e) => e.preventDefault()}
                                                type={"number"}
                                                value={device.gyro_esik}
                                                step={0.001}
                                                onChange={(event) => {
                                                    setDevice({ ...device, gyro_esik: parseFloat((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, gyro_esik: true })
                                                    }
                                                    else {
                                                        setError({ ...error, gyro_esik: parseFloat((event.target.value)) < 0.001 || parseFloat(event.target.value) > 2.000 })
                                                    }
                                                }}
                                                label="İvme(g) Eşik Değeri (Min:0.001-Max:2)"
                                            />
                                        </div>
                                    }
                                    {device.is_statik == "1" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Input
                                                onPointerEnterCapture={() => { }}
                                                onPointerLeaveCapture={() => { }}
                                                error={error.gyro_esik}
                                                crossOrigin="anonymous"
                                                min={1}
                                                onWheel={(e) => e.preventDefault()}
                                                type={"number"}
                                                value={device.gyro_esik}
                                                step={1}
                                                onChange={(event) => {
                                                    setDevice({ ...device, gyro_esik: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, gyro_esik: true })
                                                    }
                                                    else {
                                                        setError({ ...error, gyro_esik: parseInt((event.target.value)) < 1 })
                                                    }
                                                }}
                                                label="İnd. Sapma Eşik Değeri(mm) (Min:1)"
                                            />
                                        </div>
                                    }
                                    {device.is_statik == "1" &&
                                        <div className="mt-1 mb-4 w-72" >
                                            <Select placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                                value={device.dynamic_type}
                                                onChange={(item) => {
                                                    if (item && item.toString() === "none") {
                                                        setDevice({ ...device, dynamic_type: "none" });
                                                    } else if (item && item.toString() === "day") {
                                                        setDevice({ ...device, dynamic_type: "day" });
                                                    } else {
                                                        setDevice({ ...device, dynamic_type: "hour" });
                                                    }
                                                }}
                                                label="Veri Ortalama"
                                                >
                                                <Option key={"ortType1"} value={"none"} >{`Yok`}</Option>
                                                <Option key={"ortType2"} value={"hour"} >{`Saatlik`}</Option>
                                                <Option key={"ortType3"} value={"day"} >{`Günlük`}</Option>
                                            </Select>
                                        </div>
                                    }

                                    {
                                        device.device_photos.length == 0 ?
                                            <p>Cihaza ait fotoğraf bulunmamaktadır.</p>
                                            :
                                            <p>Cihaza ait fotoğraflar</p>
                                    }
                                    <div className="grid grid-cols-5 	">
                                        {

                                            device.device_photos.map((item, index) => {
                                                return (
                                                    <div style={{ position: "relative" }}>

                                                        <a href={item} target="_blank">

                                                            <img
                                                                onClick={() => {

                                                                }}
                                                                style={{
                                                                    margin: 5,
                                                                    height: 45,
                                                                    width: 45,
                                                                    border: "1px solid gray",
                                                                    cursor: "zoom-in",
                                                                    borderRadius: 10,
                                                                }} src={item} />
                                                        </a>

                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                                height: 20,
                                                                width: 20,
                                                            }}
                                                        >
                                                            <button
                                                                onClick={() => {
                                                                    setDeleteModal(true);
                                                                    setDeletePhotoIndex(index);
                                                                }
                                                                }
                                                            >

                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="red" className="w-5 h-5">
                                                                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                                                                </svg>

                                                            </button>


                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                        {device.device_photos.length < 5 &&
                                            <button
                                                disabled={progressVal !== 101}
                                                onClick={() => fileUploadRef.current.click()}
                                                style={{
                                                    height: 55,
                                                    width: 55,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={progressVal !== 101 ? "gray" : "black"}
                                                    style={{
                                                        height: 45,
                                                        width: 45,
                                                    }}>
                                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                                                </svg>

                                            </button>
                                        }
                                    </div>
                                    {deleteModal &&
                                        <DeleteModal
                                            title={"Fotoğraf Silinsin Mi"}
                                            description={"Seçmiş olduğunuz fotoğraf silinsin mi? Güncelle butonuna tıklamadığınız sürece güncellenmez."}
                                            onCancel={() => setDeleteModal(false)}
                                            onOk={() => DelPhoto()}
                                        />
                                    }

                                    <input
                                        style={{ display: "none" }}
                                        ref={fileUploadRef}
                                        type="file"
                                        placeholder="Resim yükle"
                                        name="image"
                                        accept="image/png, image/jpeg, image/jpg, image/gif"
                                        onChange={(e: any) => {
                                            UploadDevicePhoto(e.target.files[0]);
                                        }} />


                                    <div className="flex w-72 mt-2 justify-center	">
                                        {
                                            progressVal !== 101 &&
                                            <Progress placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} value={progressVal - 3} />
                                        }
                                    </div>
                                    <div className="w-72 mt-5 mb-5 flex flex-row justify-around">
                                        <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            color="red"
                                            onClick={() => {
                                                onCancel();
                                            }}
                                        >İptal</Button>
                                        <Button placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                                            disabled={progressVal != 101}
                                            color="green"
                                            onClick={() => {
                                                DeviceUpdate();
                                            }}
                                        >Güncelle</Button>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>)
}
export default SettingsModalUser;

