import { Button } from "@material-tailwind/react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { DBToDateText } from "../../../utils/DBToDateText"

const ExcelExportNew = ({ seriNo, data, dataList }) => {
    const createDownLoadData = (DownloadData) => {
        handleExport(DownloadData).then((url) => {
            const getTwoDigit = (num) => {
                if (num < 10) {
                    return "0" + num;
                }
                return num;
            }
            const date = new Date();
            const dateText = getTwoDigit(date.getDate()) + "." + getTwoDigit(date.getMonth() + 1) + "." + getTwoDigit(date.getFullYear());
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute("download", `${seriNo}_${dateText}.xlsx`);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    };

    const handleExport = (data) => {
        let rowList = ["Tarih"]
        if (data[0].temp) {
            rowList.push("Sıcaklık (°C)");
        }
        if (data[0].acc_a) {
            rowList.push("İvme A(g)");
        }
        if (data[0].acc_x) {
            rowList.push("İvme X(g)");
        }
        if (data[0].acc_y) {
            rowList.push("İvme Y(g)");
        }
        if (data[0].acc_z) {
            rowList.push("İvme Z(g)");
        }
        if (data[0].angle_x) {
            rowList.push("Roll(°)");
        }
        if (data[0].angle_y) {
            rowList.push("Pitch(°)");
        }
        if (data[0].angle_z) {
            rowList.push("Yaw(°)");
        }
        if (data[0].roll_mm) {
            rowList.push("Roll(mm)");
        }
        if (data[0].pitch_mm) {
            rowList.push("Pitch(mm)");
        }
        if (data[0].deflection) {
            rowList.push("Sapma(mm)");
        }
        if (data[0].refined_angle_x) {
            rowList.push("İnd. Roll(°)");
        }
        if (data[0].refined_angle_y) {
            rowList.push("İnd. Pitch(°)");
        }
        if (data[0].refined_angle_z) {
            rowList.push("İnd.Yaw(°)");
        }
        if (data[0].refined_roll_mm) {
            rowList.push("İnd. Roll(mm)");
        }
        if (data[0].refined_pitch_mm) {
            rowList.push("İnd. Pitch(mm)");
        }
        if (data[0].refined_deflection) {
            rowList.push("İnd.Sapma(mm)");
        }
        if (data[0].gyro_x) {
            rowList.push("Açısal Hız X(°/s)");
        }
        if (data[0].gyro_y) {
            rowList.push("Açısal Hız Y(°/s)");
        }
        if (data[0].gyro_z) {
            rowList.push("Açısal Hız Z(°/s)");
        }
        if (data[0].mag_x) {
            rowList.push("Manyetometre X(uT)");
        }
        if (data[0].mag_y) {
            rowList.push("Manyetometre Y(uT)");
        }
        if (data[0].mag_z) {
            rowList.push("Manyetometre Z(uT)");
        }
        if (data[0].battery_voltage) {
            rowList.push("Batarya (v)");
        }
        if (data[0].millis) {
            rowList.push("Veri Gönderim Süresi");
        }
        if (data[0].error_count) {
            rowList.push("Başarısız Deneme");
        }

        const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

        let rows = [];
        for (let index = 0; index < rowList.length; index++) {
            rows = [...rows, { [letters[index]]: rowList[index] }];
        }
        rows = [Object.assign({}, ...rows)];

        const finalData = [
            ...rows,
        ];

        data.map(item => {
            const dynamicRowList = [DBToDateText(item.date)];
            if (data[0].temp) {
                dynamicRowList.push(item.temp);
            }
            if (data[0].acc_a) {
                dynamicRowList.push(item.acc_a);
            }
            if (data[0].acc_x) {
                dynamicRowList.push(item.acc_x);
            }
            if (data[0].acc_y) {
                dynamicRowList.push(item.acc_y);
            }
            if (data[0].acc_z) {
                dynamicRowList.push(item.acc_z);
            }
            if (data[0].angle_x) {
                dynamicRowList.push(item.angle_x);
            }
            if (data[0].angle_y) {
                dynamicRowList.push(item.angle_y);
            }
            if (data[0].angle_z) {
                dynamicRowList.push(item.angle_z);
            }
            if (data[0].roll_mm) {
                dynamicRowList.push(item.roll_mm);
            }
            if (data[0].pitch_mm) {
                dynamicRowList.push(item.pitch_mm);
            }
            if (data[0].deflection) {
                dynamicRowList.push(item.deflection);
            }
            if (data[0].refined_angle_x) {
                dynamicRowList.push(item.refined_angle_x);
            }
            if (data[0].refined_angle_y) {
                dynamicRowList.push(item.refined_angle_y);
            }
            if (data[0].refined_angle_z) {
                dynamicRowList.push(item.refined_angle_z);
            }
            if (data[0].refined_roll_mm) {
                dynamicRowList.push(item.refined_roll_mm);
            }
            if (data[0].refined_pitch_mm) {
                dynamicRowList.push(item.refined_pitch_mm);
            }
            if (data[0].refined_deflection) {
                dynamicRowList.push(item.refined_deflection);
            }
            if (data[0].gyro_x) {
                dynamicRowList.push(item.gyro_x);
            }
            if (data[0].gyro_y) {
                dynamicRowList.push(item.gyro_y);
            }
            if (data[0].gyro_z) {
                dynamicRowList.push(item.gyro_z);
            }
            if (data[0].mag_x) {
                dynamicRowList.push(item.mag_x);
            }
            if (data[0].mag_y) {
                dynamicRowList.push(item.mag_y);
            }
            if (data[0].mag_z) {
                dynamicRowList.push(item.mag_z);
            }
            if (data[0].battery_voltage) {
                dynamicRowList.push(item.battery_voltage);
            }
            if (data[0].millis) {
                dynamicRowList.push(item.millis);
            }
            if (data[0].error_count) {
                dynamicRowList.push(item.error_count);
            }
            let itemData = [];
            for (let index = 0; index < dynamicRowList.length; index++) {
                itemData = [...itemData, { [letters[index]]: dynamicRowList[index] }];
            }
            itemData = Object.assign({}, ...itemData);
            finalData.push(itemData);
        });

        const wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, sheet, "www.rnteknoloji.com");
        const workbookBlob = workbook2blob(wb);
        const dataInfo = ["A1:V1"];
        return addStyle(workbookBlob, dataInfo);
    };

    const addStyle = (workbookBlob, dataInfo) => {
        return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                sheet.usedRange().style({
                    fontFamily: "Arial",
                    fontSize: 10,
                    wrapText: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                });
                sheet.column("A").width(20);
                sheet.column("B").width(17);
                sheet.column("C").width(17);
                sheet.column("D").width(17);
                sheet.column("E").width(17);
                sheet.column("F").width(17);
                sheet.column("G").width(17);
                sheet.column("H").width(17);
                sheet.column("I").width(17);
                sheet.column("J").width(17);
                sheet.column("K").width(17);
                sheet.column("L").width(17);
                sheet.column("M").width(17);
                sheet.column("N").width(17);
                sheet.column("O").width(17);
                sheet.column("P").width(17);
                sheet.column("Q").width(17);
                sheet.column("R").width(17);
                sheet.column("S").width(17);
                sheet.column("T").width(17);
                sheet.column("U").width(17);
                sheet.column("V").width(17);
                sheet.column("W").width(17);
                sheet.column("X").width(17);
                sheet.column("Y").width(17);
                sheet.column("Z").width(17);
                // dataInfo.map((item, index) => {
                //     sheet.range(item).merged(false).style({
                //         border: "thin",
                //         horizontalAlignment: "center",
                //     });
                // })
            });
            return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
        });
    };

    return (
        <Button
            placeholder=""
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            colorScheme={"blue"}
            variant="outline"
            height={"30px"}
            onClick={() => { createDownLoadData(data) }}
        >
            Data İndir
        </Button>
    );
};

export default ExcelExportNew;
