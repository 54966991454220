import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Dashboard from './screens/Dashboard';
import LoginScreen from './screens/LoginScreen';
import API from './API/API';
import Loading from './components/Loading';
import { IRole } from './models/User';
import ErrorPage from './screens/ErrorPage';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "./styles/date-picker.css";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("tr", tr as any);


Chart.register(CategoryScale);
function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [role, setRole] = useState<IRole>();
  const [loading, setLoading] = useState(true);

  const TokenCheck = () => {
    const token = localStorage.getItem("token");
    if (token) {
      API.getInstance()
        .TokenCheck(token)
        .then((response) => {
          API.getInstance().setAuthorizationToken(token);
          setRole(response.data.data.role);
          setIsLogin(true);
        })
        .catch(() => {
          localStorage.removeItem("token")
          setIsLogin(false);
        })
        .finally(() => {
          setLoading(false);
        })
    }
    else {
      setLoading(false);
    }
  }

  useEffect(() => {
    TokenCheck();
  }, [])

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("page");
    setIsLogin(false);
  }
  return (
    <Routes>
      <Route path="/" element={loading ? <Loading /> : isLogin ? <Dashboard role={role} onLogOut={logout} /> : <LoginScreen onLogin={(role, token) => {
        API.getInstance().setAuthorizationToken(token);
        setIsLogin(true);
        setRole(role);
      }} />} />
      {/* <Route path="/Anasayfa" element={loading ? <LoadingPage /> : isLogin ? <Detail /> : <Login setIsLogin={setIsLogin} />} /> */}
      <Route path='*' element={<ErrorPage />} />
    </Routes>

  );
}

export default App;
